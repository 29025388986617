import React from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import storesData from '../data/stores.json';
import L from 'leaflet';
import { Header, Logo } from './Home';
import fleximonLogo from "../assets/fleximon-logo.png";

// Fix for default marker icons in react-leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  gap: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AboutSection = styled.section`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-family: Montserrat, sans-serif;
  color: #009565;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: Montserrat, sans-serif;
  color: #009565;
  margin: 20px 0;
`;

const Text = styled.p`
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
`;

const StoresSection = styled.div`
  flex: 1;
  min-width: 300px;
`;

const MapSection = styled.div`
  flex: 2;
  height: 600px;
  
  @media (max-width: 768px) {
    height: 400px;
  }
  
  .leaflet-container {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
`;

const StoresList = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const StoreCard = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const CompanyInfo = styled.div`
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

function AboutUs() {
  const centerPosition = [38.722265, -9.131569]; // Lisbon center

  return (
    <>
    <Header>
        <Logo style={{ height: '90px' }} src={fleximonLogo} alt="Fleximon Logo" />
       
      </Header>
      
    <PageContainer>
        
      <AboutSection>
        <Title>Sobre Nós</Title>
        <Text>Fleximons 3D é uma marca especializada em brinquedos miniatura personalizáveis, duráveis e ecológicos, criados com impressão 3D sem desperdício. Focada em designs criativos e encantadores, atende empresas que buscam artigos de coleção para seus clientes, oferecendo também soluções à medida com edições limitadas. A marca destaca-se pela atenção ao detalhe e compromisso com a satisfação do cliente, consolidando-se no mercado B2B.</Text>
      </AboutSection>

      <ContentContainer>
        <StoresSection>
          <Subtitle>Disponível em</Subtitle>
          <StoresList>
            {storesData.stores.map((store, index) => (
              <StoreCard key={index}>
                <h3>{store.name}</h3>
                <p>{store.address}</p>
                {store.category && <small>{store.category}</small>}
              </StoreCard>
            ))}
          </StoresList>

          <CompanyInfo>
            <Subtitle>Contactos</Subtitle>
            <Text>
{/*               <strong>Nome:</strong> {storesData.companyInfo.name}<br />
              <strong>NIF:</strong> {storesData.companyInfo.vat}<br /> */}
              <strong>Email:</strong> {storesData.companyInfo.email}<br />
            </Text>
          </CompanyInfo>
        </StoresSection>

        <MapSection>
          <MapContainer 
            center={centerPosition} 
            zoom={13} 
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {storesData.stores.map((store, index) => (
              <Marker 
                key={index}
                position={store.coordinates}
              >
                <Popup>
                  <strong>{store.name}</strong><br />
                  {store.address}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </MapSection>
      </ContentContainer>
    </PageContainer>
    </>
  );
}

export default AboutUs; 