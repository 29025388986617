import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "../pages/Home";

import { PokemonProvider } from "../context/PokemonContext";
import AboutUs from "../pages/AboutUs";

function App() {
  return (
    <PokemonProvider>
      <Router>
        <Routes>
          <Route exact path="/quem-somos" element={<AboutUs />} />
          <Route exact path="/" element={<HomePage />}></Route>
          {/* <Route
            exact
            path="/:pokemonName"
            element={<PokemonPage />}
          ></Route> */}
        </Routes>
      </Router>
    </PokemonProvider>
  );
}

export default App;
